<template>
    <header class="page-header" v-if="Object.keys(contactsContent).length && Object.keys(generalContent).length">
        <div class="top-line hidden-xs visible-md">
            <div class="wrapper">
                <!-- <a class="email" :href="'mailto:' + contactsContent.contacts.list.email">
                    <span class="fas fa-envelope"></span>
                    <span class="text">
                        <span>Email:</span>
                        {{ contactsContent.contacts.list.email }}
                    </span>
                </a> -->
                <router-link class="primary-action" to="/consultation">
                    <span class="fas fa-headphones"></span>
                    <span class="text">{{ coursesPageContent.consultationSection.title[lang] }}</span>
                </router-link>
                <div class="actions">
                    <div class="links hidden-xs visible-sm">
                        <router-link to="/streams" class="email">
                            <span class="fas fa-podcast"></span>
                            <span class="text">{{ streamsContent.pageHead.title[lang] }}</span>
                        </router-link>
                    </div>
                    <div class="socials hidden-xs visible-flex-md">
                        <a :href="contactsContent.socials.list.facebook" class="social social-btn" target="_blank">
                            <span class="fab fa-facebook"></span>
                        </a>
                        <a :href="contactsContent.socials.list.youtube" class="social social-btn" target="_blank">
                            <span class="fab fa-youtube"></span>
                        </a>
                        <a :href="contactsContent.socials.list.instagram" class="social social-btn" target="_blank">
                            <span class="fab fa-instagram"></span>
                        </a>
                    </div>
                    <div class="lang">
                        <a class="item" @click="selectLang('ua')" :class="{'active': lang == 'ua'}">ua</a>
                        <a class="item" @click="selectLang('ru')" :class="{'active': lang == 'ru'}">ru</a>
                    </div>
                    <a class="phone phone-btn" :href="'tel:' + contactsContent.contacts.list.phone1" @click="fbq('track', 'Contact')">
                        <span class="fas fa-phone"></span>
                        <div class="phones-list">
                            <span class="text">{{ contactsContent.contacts.list.phone1 }}</span>
                            <span class="text">{{ contactsContent.contacts.list.phone2 }}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="main-line">
            <div class="wrapper">
                <div class="logo">
                    <a href="/"><img :src="generalContent.header.logoUrl"></a>
                </div>
                <div class="menu">
                    <nav class="nav hidden-xs visible-md">
                        <ul>
                            <li><router-link to="/">{{ generalContent.header.menu.home[lang] }}</router-link></li>
                            <li><router-link to="/about">{{ generalContent.header.menu.about[lang] }}</router-link></li>
                            <li><router-link to="/courses">{{ generalContent.header.menu.courses[lang] }}</router-link></li>
                            <li><router-link to="/gallery">{{ generalContent.header.menu.gallery[lang] }}</router-link></li>
                            <li><router-link to="/franchise">{{ generalContent.header.menu.franchise[lang] }}</router-link></li>
                            <li><router-link to="/contacts">{{ generalContent.header.menu.contacts[lang] }}</router-link></li>
                        </ul>
                    </nav>
                    <div class="lang visible-xs hidden-md">
                        <a class="item" @click="selectLang('ua')" :class="{'active': lang == 'ua'}">ua</a>
                        <a class="item" @click="selectLang('ru')" :class="{'active': lang == 'ru'}">ru</a>
                    </div>
                    <div class="actions">
                        <a :href="'tg://resolve?domain=' + contactsContent.socials.list.telegram" class="action-btn msg-btn telegram hidden-xs visible-flex-md" target="_blank">
                            <span class="btn-icon fab fa-telegram"></span>
                        </a>
                        <a :href="'viber://chat?number=' + contactsContent.socials.list.viber" class="action-btn msg-btn viber hidden-xs visible-flex-md" target="_blank">
                            <span class="btn-icon fab fa-viber"></span>
                        </a>
                        <a :href="'https://wa.me/' + contactsContent.socials.list.whatsapp" class="action-btn msg-btn whatsapp hidden-xs visible-flex-md" target="_blank">
                            <span class="btn-icon fab fa-whatsapp"></span>
                        </a>
                        <a class="btn outline icon-btn visible-xs hidden-md" @click="isMenuOpen = true"><span class="btn-icon fas fa-bars"></span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-actions visible-xs hidden-md">
            <router-link class="action-item" to="/consultation" v-if="$route.name != 'consultation'">
                <span class="fas fa-headphones"></span>
                {{ coursesPageContent.consultationSection.title[lang] }}
            </router-link>
        </div>
        <div class="mobile-menu" :class="{'active': isMenuOpen}">
            <a class="close-btn" @click="isMenuOpen = false">
                <span class="fas fa-times"></span>
            </a>
            <ul class="list">
                <li><router-link to="/">{{ generalContent.header.menu.home[lang] }}</router-link></li>
                <li><router-link to="/about">{{ generalContent.header.menu.about[lang] }}</router-link></li>
                <li><router-link to="/courses">{{ generalContent.header.menu.courses[lang] }}</router-link></li>
                <li><router-link to="/gallery">{{ generalContent.header.menu.gallery[lang] }}</router-link></li>
                <li><router-link to="/streams" v-html="generalContent.header.menu.stream[lang]"></router-link></li>
                <li><router-link to="/franchise">{{ generalContent.header.menu.franchise[lang] }}</router-link></li>
                <li><router-link to="/contacts">{{ generalContent.header.menu.contacts[lang] }}</router-link></li>
            </ul>
            <!-- <div class="lang">
                <a class="item" @click="selectLang('ua')" :class="{'active': lang == 'ua'}">ua</a>
                <a class="item" @click="selectLang('ru')" :class="{'active': lang == 'ru'}">ru</a>
                <a class="item" @click="selectLang('en')" :class="{'active': lang == 'en'}">en</a>
            </div> -->
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            isMenuOpen: false
        }
    },  
    watch: {
        '$route': function (from, to) {
            this.isMenuOpen = false;
        }
    },  
    computed: {
        lang() {
            return this.$store.getters['getSelectedLang'];
        },
        generalContent() {
            return this.$store.getters['getGeneralPageContent'];
        },
        contactsContent() {
            return this.$store.getters['getContactsPageContent'];
        },
        coursesPageContent() {
            return this.$store.getters['getCoursesPageContent'];
        },
        streamsContent() {
            return this.$store.getters['getStreamsPageContent'];
        }
    },
    methods: {
        selectLang(lang) {
            this.$store.dispatch('setLang', lang);
        }
    }
}
</script>
