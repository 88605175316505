<template>
    <footer class="page-footer" v-if="Object.keys(contactsContent).length && Object.keys(generalContent).length">
        <div class="main-content">
            <div class="wrapper">
                <div class="info">
                    <div class="logo">
                        <img :src="generalContent.footer.logoUrl">
                    </div>
                    <p class="text">{{ generalContent.footer.description[lang] }}</p>
                    <p class="socials-title">{{ pageTexts.socialsTitle[lang] }}</p>
                    <div class="socials">
                        <a :href="contactsContent.socials.list.facebook" class="social-btn social facebook" target="_blank">
                            <span class="fab fa-facebook"></span>
                        </a>
                        <a :href="contactsContent.socials.list.youtube" class="social-btn social youtube" target="_blank">
                            <span class="fab fa-youtube"></span>
                        </a>
                        <a :href="contactsContent.socials.list.instagram" class="social-btn social instagram" target="_blank">
                            <span class="fab fa-instagram"></span>
                        </a>
                        <a :href="'tg://resolve?domain=' + contactsContent.socials.list.telegram" class="msg-btn social telegram" target="_blank">
                            <span class="btn-icon fab fa-telegram"></span>
                        </a>
                        <a :href="'viber://add?number=' + contactsContent.socials.list.viber" class="msg-btn social viber" target="_blank">
                            <span class="btn-icon fab fa-viber"></span>
                        </a>
                        <a :href="'https://wa.me/' + contactsContent.socials.list.whatsapp" class="msg-btn social whatsapp" target="_blank">
                            <span class="btn-icon fab fa-whatsapp"></span>
                        </a>
                    </div>
                    <div class="docs">
                        <a class="doc" :href="doc.link" target="_blank" v-for="(doc, index) in generalContent.footer.docs" :key="index">
                            <span class="fas fa-file-pdf"></span>
                            <span class="value">{{ doc.title[lang] }}</span>
                        </a>
                    </div>
                </div>
                <div class="footer-map">
                    <div class="list-section">
                        <h3 class="fs-title">{{ pageTexts.pages[lang] }}</h3>
                        <div class="list">
                            <ul>
                                <li><router-link to="/">{{ generalContent.header.menu.home[lang] }}</router-link></li>
                                <li><router-link to="/about">{{ generalContent.header.menu.about[lang] }}</router-link></li>
                                <li><router-link to="/courses">{{ generalContent.header.menu.courses[lang] }}</router-link></li>
                                <li><router-link to="/franchise">{{ generalContent.header.menu.franchise[lang] }}</router-link></li>
                                <li><router-link to="/contacts">{{ generalContent.header.menu.contacts[lang] }}</router-link></li>
                                <li><router-link to="/streams" v-html="generalContent.header.menu.stream[lang]"></router-link></li>
                                <li><router-link to="/terms" v-html="generalContent.header.menu.terms[lang]"></router-link></li>
                                <li><router-link to="/gallery" v-html="generalContent.header.menu.gallery[lang]"></router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="list-section">
                        <h3 class="fs-title">{{ pageTexts.categories[lang] }}</h3>
                        <div class="list">
                            <ul>
                                <li class="dotted" v-for="(course, index) in courses.reverse().slice(0, 6)" :key="index"><router-link :to="'/courses/' + course.code">{{ course.title[lang] }}</router-link></li>
                            </ul>
                            <ul>
                                <li class="dotted" v-for="(course, index) in courses.reverse().slice(6, 12)" :key="index"><router-link :to="'/courses/' + course.code">{{ course.title[lang] }}</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-line">
            <div class="wrapper">
                <p class="copyright">{{ generalContent.footer.copyright[lang] }}</p>
                <div class="contacts">
                    <a :href="'mailto:' + contactsContent.contacts.list.email" class="contact phone-btn">
                        <span class="fas fa-paper-plane"></span>
                        <span class="text">{{ contactsContent.contacts.list.email }}</span>
                    </a>
                    <a :href="'tel:' + contactsContent.contacts.list.phone1" class="contact phone-btn">
                        <span class="fas fa-phone"></span>
                        <span class="text">{{ contactsContent.contacts.list.phone1 }}</span>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            pageTexts: {
                pages: {
                    ua: 'Сторінки',
                    ru: 'Страницы',
                    en: 'Pages'
                },
                categories: {
                    ua: 'Напрямки навчання',
                    ru: 'Направления обучения',
                    en: 'Categories'
                },
                socialsTitle: {
                    ua: 'Нас легко знайти',
                    ru: 'Нас легко найти',
                    en: 'We are easy to find'
                }
            }
        }
    },
    computed: {
        lang() {
            return this.$store.getters['getSelectedLang'];
        },
        generalContent() {
            return this.$store.getters['getGeneralPageContent'];
        },
        contactsContent() {
            return this.$store.getters['getContactsPageContent'];
        },
        courses() {
            return this.$store.getters['getCourses'].filter(course => {
                return !!course.title[this.lang].length;
            });
        }
    }
}
</script>
