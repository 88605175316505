<template>
    <div class="page-phone-btn">
        <div class="phones-list">   
            <a :href="'tel: ' + pageContent.contacts.list.phone1" class="item">
                <img src="./../assets/images/icons/vodafon-logo.png" alt="">
                {{ pageContent.contacts.list.phone1 }}
            </a>
            <a :href="'tel: ' + pageContent.contacts.list.phone2" class="item">
                <img src="./../assets/images/icons/kievstar-logo.png" alt="">
                {{ pageContent.contacts.list.phone2 }}
            </a>
        </div>
        <a class="phone-btn">
            <span class="fas fa-phone"></span>
        </a>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },  
    computed: {
        lang() {
            return this.$store.getters['getSelectedLang'];
        },
        pageContent() {
            return this.$store.getters['getContactsPageContent'];
        }
    }
}
</script>
