<template>
    <section class="contact-us-banner" v-if="pageContent">
        <div class="wrapper">
            <div class="cu-form">
                <h4 class="cu-title">{{ pageContent.title[lang] }}</h4>
                <p class="cu-description">{{ pageContent.subTitle[lang] }}</p>
                <div class="cu-form-group" v-if="!isMailSended && !isMailSendError">
                    <div class="form-field" :class="{'error': (!form.phone.length && isFormError)}">
                        <input class="field" type="text" v-model="form.phone" :placeholder="pageContent.fieldPlaceholder[lang]">
                        <div class="error-label">This field is required</div>
                    </div>
                    <a class="btn outline btn-phone-submit" :class="{'loading': formSendInProcess}" @click="sendMail()">
                        <div class="loader line-scale-pulse-out">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <span class="btn-icon fas fa-paper-plane"></span>
                        <span class="btn-text">{{ pageContent.btnText[lang] }}</span>
                    </a>
                </div>
                <div class="msg" v-if="isMailSended">
                    <div class="icon"><span class="fas fa-laugh-beam"></span></div>
                    <p class="title">{{ pageTexts.form.sended[lang] }}</p>
                </div>
                <div class="msg" v-if="isMailSendError">
                    <div class="icon"><span class="fas fa-sad-tear"></span></div>
                    <p class="title">{{ pageTexts.form.error[lang] }}</p>
                </div>
                <p class="sub-text" v-if="!isMailSended && !isMailSendError">{{ pageContent.fieldDescription[lang] }}</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            isMailSended: false,
            isMailSendError: false,
            isFormHidden: false,
            formSendInProcess: false,
            isFormError: false,
            form: {
                phone: ''
            },
            pageTexts: {
                form: {
                    sended: {
                        ua: 'Заявку успішно відправлено',
                        ru: 'Заявка успешно отправлено',
                        en: 'Application successfully sent'
                    },
                    error: {
                        ua: 'Щось пішло не так. Спробуйте перезавантажити сторінку та надіслати анкету знову',
                        ru: 'Что-то пошло не так. Попробуйте перезагрузить страницу и отправить анкету снова',
                        en: 'Something went wrong. Try reloading the page and submitting the form again.'
                    }
                }
            }
        }
    },  
    computed: {
        lang() {
            return this.$store.getters['getSelectedLang'];
        },
        pageContent() {
            return this.$store.getters['getGeneralPageContent'].callbackForm;
        }
    },
    methods: {
        sendMail() {
            let mail = this.form;

            if (!mail.phone.length) {
                this.isFormError = true;
            } else {
                this.isFormError = false;
                this.formSendInProcess = true;

                $.ajax({ 
                    url: '../sendmail.php', 
                    data: mail,
                    type: 'POST',
                    success: function (data) {
                        this.isMailSended = true;
                        fbq('track', 'CompleteRegistration');
                        dataLayer.push({'event': 'GTM_tel'});
                    }.bind(this),
                    fail: function (err) {
                        this.isMailSendError = true;
                        console.error(err);
                    }.bind(this),
                    always: function () {
                        this.isFormHidden = true;
                        this.formSendInProccess = false;
                    }.bind(this)
                });
            }
        }
    }
}
</script>
