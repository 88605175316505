import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/franchise',
    name: 'franchise',
    component: () => import('../views/FranchiseView.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('../views/GalleryView.vue')
  },
  {
    path: '/consultation',
    name: 'consultation',
    component: () => import('../views/ConsultationView.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/ContactsView.vue')
  },
  {
    path: '/courses',
    name: 'courses',
    component: () => import('../views/CoursesView.vue')
  },
  {
    path: '/courses/:id',
    name: 'course',
    component: () => import('../views/CourseView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/TermsView.vue')
  },
  {
    path: '/streams',
    name: 'streams',
    component: () => import('../views/StreamsView.vue')
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import('../views/OrderView.vue')
  },
  {
    path: '/blog/:id',
    name: 'post',
    component: () => import('../views/PostView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/404.vue')
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    }
});

router.beforeEach((to, from, next) => {
    next();
    if (!!router.app.$store) {
        let meta = router.app.$store.getters['getMetaInfo'];
        var product = '';
        if (to.name == 'course') {
          product = router.app.$store.getters['getCourseById'](to.params.id).title.ua;
        }
        let target = to.name;
        document.querySelector('title').innerText = product + meta.pages[target].title;
        document.head.querySelector('meta[name="description"]').setAttribute('content', meta.pages[target].description);
        document.head.querySelector('meta[name="keywords"]').setAttribute('content', meta.pages[target].keywords);
    }
})

export default router
