<template>
    <section v-if="generalContent" class="info-banner" :class="{'active': isActive}">
        <button class="close-btn" @click="close()">
            <span class="fas fa-times"></span>
        </button>
        <div class="ib-content">
            <p class="text">{{ generalContent.infoBanner.text }}</p>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {    
            isActive: true
        }
    },  
    computed: {
        lang() {
            return this.$store.getters['getSelectedLang'];
        },
        generalContent() {
            return this.$store.getters['getGeneralPageContent'];
        }
    },
    methods: {
        close() {
            this.isActive = false;
        }
    }
}
</script>
